<template>
  <div v-if="isDataLoaded">
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="1000"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Edit Staff Account</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="createForm" class="py-3">
            <div class="h-75px" v-if="is_client_required">
              <v-autocomplete
                dense
                outlined
                v-model="formData.customer_id"
                :items="serverData.clients"
                item-name="text"
                item-value="index"
                label="Customer"
                clearable
                :error-messages="customer_idErrors"
                @input="$v.formData.customer_id.$touch()"
                @blur="$v.formData.customer_id.$touch()"
              ></v-autocomplete>
            </div>
            <div class="h-75px">
              <v-text-field
                dense
                outlined
                v-model="formData.name"
                :counter="40"
                label="Name"
                required
                clearable
                :error-messages="nameErrors"
                @input="$v.formData.name.$touch()"
                @blur="$v.formData.name.$touch()"
              ></v-text-field>
            </div>
            <div class="h-75px">
              <v-text-field
                dense
                outlined
                v-model="formData.email"
                :counter="40"
                label="Email"
                required
                clearable
                :error-messages="emailErrors"
                @input="$v.formData.email.$touch()"
                @blur="$v.formData.email.$touch()"
              ></v-text-field>
            </div>
            <div class="h-75px">
              <v-text-field
                dense
                outlined
                v-model="formData.description"
                label="Description"
                clearable
              ></v-text-field>
            </div>
            <div class="h-75px">
              <v-file-input
                outlined
                v-model="formData.photo"
                show-size
                counter
                multiple
                dense
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                label="Photo"
                clearable
              ></v-file-input>
            </div>
            <v-card v-if="is_role_required">
              <v-toolbar-title
                class="primary lighten-3 h3 text-white text-center mt-4 pa-1"
                >Roles</v-toolbar-title
              >
              <div
                v-if="formData.roles.length === 0"
                class="text-danger text-left text-h6 ml-4"
              >
                Role is required!
              </div>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="4"
                    v-for="role in serverData.roles"
                    :key="role.value"
                  >
                    <v-switch
                      class="mt-0 pt-0"
                      hide-details
                      v-model="formData.roles"
                      color="primary"
                      :label="role.text"
                      :value="role.index"
                      :error-messages="rolesErrors"
                      @input="$v.formData.roles.$touch()"
                      @blur="$v.formData.roles.$touch()"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!--end::Body-->
          </form>

          <!--begin::Actions-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { getToken } from "@/core/services/jwt.service";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { editItemMessage } from "@/core/constants/globalVariables";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      email: { required, email },
      customer_id: { required },
      roles: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      name: "",
      email: "",
      description: "",
      customer_id: null,
      photo: null,
      roles: [],
    },
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].url && errors.push("This url is invalid");
      }
      if ("numeric" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: { ...this.$store.getters.getItemForAction }.id };
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/authorizations/accounts/edit`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        data: data,
      };
      axios(config)
        .then((response) => {
          this.serverData = response.data;
          this.formData = { ...this.serverData }.user;
          this.formData.photo = null;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] && item[0] !== "image" && item[0] !== "photo") ||
          item[1] === 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else {
            data.append(item[0], item[1]);
          }
        } else if (item[1] && (item[0] === "image" || item[0] === "photo")) {
          // if (
          //   typeof item[1] === "object" &&
          //   !Array.isArray(item[1]) &&
          //   item[1] !== null
          // ) {
          data.append(item[0], item[1][0]);
          // }
        }
      });
      return data;
    },
    submitEditForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.convertToFormData();
        let config = {
          method: "post",
          url: `${process.env.VUE_APP_BASE_URL}/authorizations/accounts/update`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          data: data,
        };
        axios(config)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `${editItemMessage}`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData = {
        name: "",
        email: "",
        description: "",
        parent_id: null,
        customer_id: null,
        photo: null,
        roles: [],
      };
    },
    toggleModal() {
      if (this.dialog) this.resetEditForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
  },
  computed: {
    is_client_required: function () {
      return !!this.serverData.is_client_required;
    },
    is_role_required: function () {
      return !!this.serverData.is_role_required;
    },
    isDataLoaded: function () {
      return !!this.serverData;
    },
    nameErrors() {
      return this.handleFormValidation("name");
    },
    customer_idErrors() {
      if (this.is_client_required) {
        return this.handleFormValidation("customer_id");
      } else {
        return null;
      }
    },
    emailErrors() {
      return this.handleFormValidation("email");
    },
    rolesErrors() {
      if (this.is_role_required) {
        return this.formData.roles.length > 0 ? null : "Roles are required.";
      } else {
        return null;
      }
    },
  },
};
</script>
