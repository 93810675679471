<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="serverData"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add New Staff Account</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <form id="createForm" class="py-3">
          <div class="h-75px" v-if="!user_data.is_client">
            <v-autocomplete
              dense
              outlined
              v-model="formData.customer_id"
              :items="serverData.clients"
              item-name="text"
              item-value="index"
              label="Customer"
              clearable
              :error-messages="customer_idErrors"
              @input="$v.formData.customer_id.$touch()"
              @blur="$v.formData.customer_id.$touch()"
            ></v-autocomplete>
          </div>
          <div class="h-75px">
            <v-text-field
              dense
              outlined
              v-model="formData.name"
              label="Name"
              required
              clearable
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="h-75px">
            <v-text-field
              dense
              outlined
              v-model="formData.email"
              label="Email"
              clearable
              :error-messages="emailErrors"
              @input="$v.formData.email.$touch()"
              @blur="$v.formData.email.$touch()"
            ></v-text-field>
          </div>
          <div class="h-75px">
            <v-text-field
              dense
              outlined
              v-model="formData.description"
              label="Description"
              clearable
            ></v-text-field>
          </div>
          <div class="h-75px">
            <v-file-input
              outlined
              v-model="formData.photo"
              show-size
              counter
              multiple
              dense
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              label="Photo"
              clearable
            ></v-file-input>
          </div>
          <v-card v-if="serverData.is_role_required">
            <v-toolbar-title
              class="primary lighten-1 h3 text-white text-center mt-4 pa-1"
              >Roles</v-toolbar-title
            >
            <div
              v-if="formData.roles.length === 0"
              class="text-danger text-left text-h6 ml-4"
            >
              Role is required!
            </div>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="4"
                  v-for="role in serverData.roles"
                  :key="role.value"
                >
                  <v-switch
                    class="mt-0 pt-0"
                    hide-details
                    v-model="formData.roles"
                    color="primary"
                    :label="role.text"
                    :value="role.index"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!--end::Body-->
        </form>

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import { addItemMessage } from "@/core/constants/globalVariables";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      email: { required, email },
      customer_id: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      name: "",
      email: "",
      description: "",
      parent_id: null,
      customer_id: null,
      photo: null,
      roles: [],
    },
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("email" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/authorizations/accounts/create`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      };
      axios(config)
        .then((response) => {
          this.serverData = response.data;
          if (this.user_data.is_client) {
            this.formData.customer_id = this.user_data.id;
          }
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] && item[0] !== "image" && item[0] !== "photo") ||
          item[1] === 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else {
            data.append(item[0], item[1]);
          }
        } else if (item[1] && (item[0] === "image" || item[0] === "photo")) {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.convertToFormData();
        let config = {
          method: "post",
          url: `${process.env.VUE_APP_BASE_URL}/authorizations/accounts/store`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          data: data,
        };
        axios(config)
          .then(() => {
            Swal.fire({
              title: "Created",
              text: `${addItemMessage}`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        name: null,
        email: null,
        description: null,
        parent_id: null,
        customer_id: null,
        photo: null,
        roles: [],
      };
    },
  },
  computed: {
    user_data: function () {
      return this.$store.getters.currentUser.data;
    },
    nameErrors() {
      return this.handleFormValidation("name");
    },
    customer_idErrors() {
      return this.handleFormValidation("customer_id");
    },
    emailErrors() {
      return this.handleFormValidation("email");
    },
  },
};
</script>
